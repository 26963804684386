$grid-breakpoints: (
  tablet: 600px,
  desktop: 992px,
  wide-screen: 1900px,
);

$container-max-widths: (
  tablet: 700px,
  desktop: 1000px,
  wide-screen: 2700px,
);

@mixin tablet {
  @media (min-width: map-get($grid-breakpoints, tablet)) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: map-get($grid-breakpoints, desktop)) {
    @content;
  }
}

@mixin wide-screen {
  @media (min-width: map-get($grid-breakpoints, wide-screen)) {
    @content;
  }
}

.responsive-container {
  width: 100%;

  @include desktop {
    max-width: map-get($container-max-widths, desktop);
    padding: 0;
  }
}
