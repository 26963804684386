@import "../../global/index.scss";

$black: #282828;

.input-question-container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed #bababa;
  padding: 24px 0;
  position: relative;
  @include desktop {
    cursor: pointer;
  }
}

.titles-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.icon-circel {
  width: 24px;
  height: 24px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px 0 0;
  transition: 0.3s ease-in-out;
}

.number-icon {
  border: 1.575px solid #dfebfc;
  background: #dfebfc;
  font-family: $main-font-regular;
  font-size: $font-size-body;
}

.v-circle {
  transition: 0.3s ease-in-out;
}

.question-title {
  max-width: 90%;
}

.results-value {
  color: #0a7fff;
}

.results-container {
  position: relative;
  animation: resultsAnimation 0.2s ease-in forwards;
  margin: 16px 0 0 32px;
  display: inline-flex;
  align-items: center;
  @include desktop {
    cursor: pointer;
  }
}

.edit-icon {
  width: 17.5px;
  height: auto;
  margin: 0 0 0 12px;
}
