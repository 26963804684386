@import "../../global/index.scss";

.sidebar {
  display: none;

  @include desktop {
    display: flex;
    height: 100%;
    box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.08);
    width: 242px;
    padding: 117px 32px;
    flex-direction: column;
    z-index: 1;

    .dash-line {
      height: 64px;
      border-left: 1px dashed $secondary-grey;
      margin-left: 9px;
    }

    .sidebar-item-container {
      display: contents;
    }
  }
}
