@import "../../global/index.scss";

.main {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .small-padding {
    padding: 24px 20px !important;
    @include tablet {
      padding: 24px 32px !important;
    }
    @include desktop {
      padding: 40px 32px !important;
    }
  }

  .hide-title-mobile {
    display: none;
    @include desktop {
      display: block;
    }
  }

  .content {
    flex-basis: 0;
    flex-grow: 1;
    overflow-y: scroll;
    padding: 32px 20px;

    @include desktop {
      padding: 40px 32px;
    }

    @include tablet {
      padding: 32px;
    }

    .page-title {
      display: block;

      border-bottom: 1px solid #00487a;
      width: 100%;

      h1 {
        margin-block-start: 0;
        margin-block-end: 16px;
      }

      h2 {
        margin-block-end: 16px;

        @include tablet {
          max-width: 75%;
        }
      }
    }

    .show-only-desktop {
      display: none !important;
      @include desktop {
        display: block !important;
      }
    }

    .title-bounce {
      position: relative;
      animation: scaleUp 0.5s ease-out forwards;
      transform-origin: top left;
    }
  }
}
