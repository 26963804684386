@import "../../global/index.scss";

.body-wrapper {
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $page-back-grey;
  align-items: center;

}
