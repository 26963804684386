@import "../../global/index.scss";

.popup {
  background-color: $secondary-blue-light;
  // position: fixed;
  display: flex;

  -webkit-transition: opacity 200ms, visibility 200ms;
  transition: opacity 200ms, visibility 200ms;

  visibility: visible;
  opacity: 1;

  .label {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 20px;
  }

  .close {
    cursor: pointer;

    .close-icon {
      height: 8px;
      width: 8px;
    }
  }
}

.popup-cookies {
  @extend .popup;
  // bottom: 19px;
  left: 19px;
  padding: 12px 20px 20px 20px;
  flex-direction: column;
  align-items: flex-end;
  // width: calc(100% - 38px);

  // @include desktop {
  //   display: none;
  // }
  width: 100%;
  margin: 0 0 22px 0;
  @include tablet {
    margin: 0 0 40px 0;
  }

  @include desktop {
    margin: 40px 0;
  }
  .close {
    margin-right: -4px;
  }
}

.popup-button-note {
  @extend .popup;
  position: fixed !important;
  padding: 12px;
  max-width: calc(100vw - 64px);
  flex-direction: row-reverse;
  align-items: flex-start;
  bottom: 93px;
  right: 20px;
  column-gap: 8px;

  .close {
    padding-left: 7px;
  }

  .label {
    padding: 0;
  }

  @include tablet {
    right: 32px;
    bottom: 73px;
    max-width: 466px;
  }

  @include desktop {
    right: 20px;
    bottom: 73px;
    right: calc((100vw - 1000px) / 2 + 32px);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: calc((100vw - 79px) / 3 - 12px);
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: $secondary-blue-light;
    border-bottom: 0;
    margin-left: -8px;
    margin-bottom: -8px;

    @include tablet {
      right: 72px;
    }
  }
}

.closed {
  opacity: 0;
  // visibility: hidden;
  display: none;
}

.open {
  opacity: 1;
  // visibility: visible;
  display: flex;
}
