@import "../../global/index.scss";

.settings-bar {
  position: fixed;
  background-color: white;
  right: 0;
  top: 92.02px;
  height: calc(100vh - 92.02px);
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.08);
  padding: 24px 20px;
  z-index: 3;

  -webkit-transition: right 200ms;
  transition: right 200ms;

  @include tablet {
    top: 74.73px;
    height: calc(100vh - 74.73px);
  }

  @include desktop {
    top: 64.02px;
    height: calc(100vh - 64.02px);
    width: calc(50vw - 500px + 108px + 18px);
    &.hide {
      right: calc(-50vw + 500px - 108px - 18px) !important;
    }
  }

  &.hide {
    right: -200px;
  }

  .sub-title {
    font-size: 16px;
    font-weight: 500;
    font-family: "Roboto-Medium";
    margin: 18px 0;
  }

  .radio-options-containet {
    .option-containet {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      margin: 20px 0;

      .icon-containet {
        width: 20px;
        height: auto;
        margin: 0 8px 0 0;
      }
    }
  }
}

.dim {
  position: fixed;
  background-color: $primary-black;
  opacity: 0.2;
  width: 100vw;
  height: calc(100vh - 92.02px);
  top: 92.02px;
  z-index: 2;

  -webkit-transition: opacity 200ms, visibility 200ms;
  transition: opacity 200ms, visibility 200ms;

  visibility: visible;

  @include tablet {
    top: 74.73px;
    height: calc(100vh - 74.73px);
  }

  @include desktop {
    top: 64.02px;
    height: calc(100vh - 64.02px);
  }

  &.hide {
    visibility: none;
    opacity: 0;
  }
}

.none {
  display: none;
}