@import "../../global/index.scss";

$black: #282828;
$grey: #bababa;

.h2-class {
  color: $questions-titles-black !important;
  font-family: $main-font-regular !important;
  margin: 8px 0 !important;
}

.h5-class {
  margin: 6px 0 !important;
}

.h4-class {
  font-size: 14px !important;
  margin: 12px 0 0 0 !important;
}

.mobile-text {
  display: flex !important;
  @include tablet {
    display: none !important;
  }
  @include desktop {
    display: none !important;
  }
}

.desktop-text {
  display: none !important;
  @include tablet {
    display: flex !important;
  }
  @include desktop {
    display: flex !important;
  }
}

.span-class {
  font-weight: 600 !important;
  font-size: $font-size-label !important;
  color: #2e2e2e !important;
}

.plus-icon {
  width: 10px;
  height: auto;
  margin: 0 8px;
}

.result-card-container {
  background: #f4f7fb;
  padding: 16px;
  border-left: 4px solid;
  margin: 16px 0;
}

.technology-title-container {
  font-size: 14px;
  border: 1px solid;
  line-height: 22px;
  padding: 1px 12px;
  width: fit-content;
}

.color-green {
  color: $secondary-green;
  border-color: $secondary-green;
}

.color-orange {
  color: $secondary-orange;
  border-color: $secondary-orange;
}

.summary-details-container {
}

.pointer {
  cursor: pointer;
}

.details-container {
  padding: 10px 0 0;
  border-top: 0.5px solid #2e2e2e;
  margin: 12px 0 0 0;

  .bullets-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 12px 0;
    .bullets-left-section {
      margin: 0 0 -8px 0;
      @include tablet {
        margin: 0 24px -8px 0 !important;
      }
    }
    .bullets-right-section {
      margin: 0 0 -8px 0;
    }
  }
}

.links-container {
  margin: 15px 0 0 0;
  display: flex;
  .h4-class {
    color: #00487a;
    border-bottom: 1.5px solid #0a7fff;
    display: flex;
    padding: 0 0 5px;
    width: fit-content;
  }
}

.links-icon {
  width: auto;
  height: 16px;
  margin: 0 0 0 10px;
}

.alert-message-container {
  display: flex;
  align-items: baseline;
  margin: 8px 0 10px 0;
  max-width: 480px;
}

.alert-text {
  margin: 0 !important;
}

.alert-icon {
  width: 12px;
  height: auto;
  margin: 0px 12px 0 0;
}

.right-space {
  margin: 0 10px 0 0 !important;
}

.left-space {
  margin: 0 0 0 10px !important;
}

.list-dot {
  margin: 4px 6px !important;
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  line-height: 18px;
}

.inner-dot {
  margin: 4px 0 8px 25px !important;
}
