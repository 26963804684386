@import "../../global/index.scss";

.sidebar-item {
  display: flex;
  column-gap: 10px;
  align-items: center;

  .icon {
    width: 18px;
    height: 18px;
  }

  .label {
    color: $primary-blue-dark;
    font-family: $main-font-light;
    font-size: $font-size-body;

    &.current {
      font-family: $main-font-regular;
    }

    &.remain {
      color: $secondary-grey;
    }
  }
}
