@import "../../global/index.scss";

$size: 60px;

.prog-bar-wrapper {
  width: $size;
  height: $size;

  .prog-bar-label {
    width: 100%;
    height: 100%;
    position: relative;
    top: -$size;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-blue-dark;

    span {
      color: $primary-blue;
      padding: 0 3px;
    }
  }
}
