@mixin background-image-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@mixin reset-button-style {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.mobile-tablet-hide {
  display: none;

  @include desktop {
    display: block;
  }
}

.desktop-hide {
  @include desktop {
    display: none;
  }
}
