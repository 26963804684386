@import "../../global/index.scss";

.result-note-container {
  background: #f2f2f2;
  padding: 16px;
  margin: 24px 0;
}

.title {
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 16px;
}

.title-blue {
  color: #0a7fff;
}

.title-default {
}

.paragraph-text {
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0;
}

.paragraph-bold {
}

.paragraph-default {
}

.link {
  color: #00487a !important;
  border-bottom: 1.5px solid #0a7fff;
  display: flex;
  padding: 0 0 5px;
  width: fit-content;
  text-decoration: none;
  font-size: 14px;
  margin: 16px 0 0 0;
}

.links-icon {
  width: auto;
  height: 16px;
  margin: 0 0 0 10px;
}
