@import "./index.scss";

.slide-up-results {
  animation: slideUp 1.2s ease-out forwards;
}

.slide-up-question {
  animation: slideUp 0.4s ease-out forwards;
}

.open {
  animation: open 0.2s ease-out forwards;
}

.close {
  animation: close 0.2s ease-out forwards;
}
