@import "../../global/index.scss";

sup {
  font-size: 10px;
}

.title {
  font-size: 18px;
  border-bottom: 1px solid;
  padding: 0 0 8px 0;
  margin: 0;
}

.bold {
  font-family: $main-font-medium !important;
}

.sub-Title {
  padding: 0;
  width: 100%;
  text-align: center;
  justify-content: space-between;
}

.dashedLine {
  width: calc(50% - 18px);
  border-bottom: 0.5px dashed;
}

.color-green {
  color: $secondary-green;
  border-color: $secondary-green;
}

.color-orange {
  color: $secondary-orange;
  border-color: $secondary-orange;
}

.sub-title-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000 !important;
  line-height: 0.1em !important;
  margin: 10px 0 20px;
  justify-content: space-between;
}

h6 {
  font-size: 14px;
  padding: 0 0 8px 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-green {
}

.color-orange {
}
