@font-face {
  font-family: "Roboto";
  src: url("../../fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Roboto-Light";
  src: url("../../fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../../fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto-Bold.ttf";
  src: url("../../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Roboto-Black";
  src: url("../../fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
}

$main-font-light: "Roboto-Light";
$main-font-regular: "Roboto-Regular";
$main-font-medium: "Roboto-Medium";

$font-size-body: 16px;
$font-size-h1: 24px;
$font-size-h2: 18px;
$font-size-h3: 18px;
$font-size-h4: 16px;

$font-size-label: 12px;
$font-size-popup-label: 18px;