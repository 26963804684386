@import "../../global/index.scss";

.welcome-container {
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;

  @include desktop {
    flex-direction: row;
  }

  .hero-image {

    @include background-image-cover;
    background-position-x: 70%;
    background-position-y: 43%;
    background-image: url("../../../../assets/images/hero-image.jpg");
    height: 332px;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: flex-end;

    @include tablet {
      height: 569px;
      background-position-y: 40%;
    }

    @include desktop {
      width: 47.5%;
      height: 100vh;
      background-position-y: 53%;
    }

    .welcome-image {
      position: absolute;
      width: 251px;
      left: 0%;
      top: 5.5%;
      opacity: 0.8;
      height: 78px;
      @include tablet {
        height: 137px;
        width: 441px;
        top: 8.5%;
      }
      @include desktop {
        height: 20%;
        width: 39.3%;
        top: 14%;
        left: -2.92%;
      }
      @include wide-screen {
        width: 34%;
        height: 24%;
        top: 12%;
        left: -2%;
      }
    }

    .logo-image {
      position: absolute;
      left: 4.9%;
      top: 1.97%;
      @include tablet {
        width: 104px;
        height: 28px;
        left: 4.25%;
        right: 82.19%;
        top: 3.16%;
        bottom: 94.14%;
      }
  
      @include desktop {
        width: 10.4%;
        height: 4.4%;
        top: 6.5%;
        left: 3.5%;
      }
    }
    .language-picker {
      height: fit-content;
      @include tablet {
        padding: 32px;
      }

      @include desktop {
        display: none;
      }
    }
  }

  .homepage-contentainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    flex-grow: 1;
    flex-basis: 0;

    @include tablet {
      padding: 32px;
    }

    @include desktop {
      width: 50%;
      padding: 3.5rem;
      height: 100vh;
      justify-content: center;
    }

    h1 {
      font-size: 32px;
      font-family: $main-font-regular;
    }

    p {
      color: $primary-blue-dark;
      font-size: 20px;
    }

    .button-container {
      width: 100%;
    }

    .language-picker {
      display: none;
      max-width: 150px;
      margin-bottom: 30px;

      @include desktop {
        display: block;
      }
    }

    .homepage-content {
      flex-grow: 1;
      flex-basis: 0;
      margin-bottom: 30px;
      @include tablet {
        flex-grow: 1;
      }
      @include desktop {
        flex-grow: 0;
      }
    }

    .popup-space {
      display: none;
      @include desktop {
        display: block;
        width: 100%;
        height: 185px;
      }
    }
  }
}
