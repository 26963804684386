@import "../../global/index.scss";

.dropdown-text {
  font-size: 14px !important;
  margin: 12px 0 0 0 !important;
}

.mobile-text {
  display: flex !important;
  @include tablet {
    display: none !important;
  }
  @include desktop {
    display: none !important;
  }
}

.desktop-text {
  display: none !important;
  @include tablet {
    display: flex !important;
  }
  @include desktop {
    display: flex !important;
  }
}

.bold-values {
  font-weight: 600 !important;
  font-size: $font-size-label !important;
  color: #2e2e2e !important;
}

.margin {
  margin: 4px 0;
}

.plus-icon {
  width: 10px;
  height: auto;
  margin: 0 8px;
}

.condition-card-container {
  background: #f4f7fb;
  padding: 16px;
  border-left: 4px solid;
  margin: 0 0 16px 0;
  @include desktop {
    margin: 24px 0 16px 0;
  }
}

.card-title {
  font-size: 16px;
  line-height: 22px;
}

.color-blue {
  color: $primary-blue;
  border-color: $primary-blue;
}

.summary-details-container {
  margin: 4px 0 0 0;
  .summary-fields {
    margin: 4px 0;
  }
}

.pointer {
  cursor: pointer;
}

.details-container {
  padding: 10px 0 0;
  border-top: 0.5px solid #2e2e2e;
  margin: 12px 0 0 0;

  .bullets-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 12px 0;
    .bullets-left-section {
      margin: 0 0 -8px 0;
      @include tablet {
        margin: 0 24px -8px 0 !important;
      }
    }
    .bullets-right-section {
      margin: 0 0 -8px 0;
    }
  }
}

.list-field {
  margin: 4px 0px !important;
  line-height: 18px;
}
