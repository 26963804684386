@import "../../global/index.scss";

.inputs-container {
  margin: 32px 0;
}

.role-picker {
  height: fit-content;
  margin-bottom: 30px;
}

.select-picker{
  margin: 24px 0;
    @include desktop {
        max-width: 336px;
      }
}