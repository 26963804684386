@import "../../global/index.scss";

.select-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 16px 0 0 0;

  @include desktop {
    max-width: 695px;
  }
}

.one-column {
  flex-direction: column;
}

.two-column {
  flex-direction: row;
}

.option-container {
  display: flex;
  padding: 0 16px;
  height: 60px;
  align-items: center;
  margin: 8px 0;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  .option-title {
    padding: 0 0 0 8px;
  }
  width: calc(100vw - 40px);

  @include desktop {
    width: 335px !important;
  }

  @include tablet {
    width: calc(50vw - 12px - 32px);
  }
}

.option-selected {
  background-color: #dfebfc;
  border: 1px solid #0a7fff;
  transition: 0.2s ease;
  h3 {
    color: #0a7fff;
  }
}

.option-unselected {
  background-color: #ffffff;
  border: none;
}

.selected-default {
  width: 20px;
  height: auto;
}

.unselected-default {
  width: 20px;
  height: auto;
}

.selected-pictures {
}

.unselected-pictures {
}
