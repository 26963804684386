@import "../../global/index.scss";

.top-header-wrapper {
  width: 100%;
  background-color: $primary-blue;
  display: flex;
  justify-content: center;
  z-index: 5;

  .top-header-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 48px 20px 16px 20px;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;

    @include tablet {
      padding: 18px 32px 18px 32px;
    }

    @include desktop {
      justify-content: flex-start;
      column-gap: 24px;
    }

    .exit {
      @include reset-button-style;
      color: white;

      @include desktop {
        font-size: inherit;
      }
    }

    .logo-container {
      .top-logo {
        width: 100%;
        max-width: 92px;
        min-height: 24.02px;

        @include tablet {
          max-width: 133px;
        }

        @include desktop {
          max-width: 92px;
        }
      }

      @include desktop {
        flex-grow: 1;
        flex-basis: 0;
      }
    }

    .settings-icon {
      width: 18px;
      cursor: pointer;
      margin: 0 0 0 33px;
    }
    @include tablet {
      margin: 0;
    }
    @include desktop {
      margin: 0;
    }
  }
}
