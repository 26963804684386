@import "../../global/index.scss";

.checkbox-container {
  display: flex;
  align-items: flex-start;
  margin: 16px 0;
  cursor: pointer;
}

.checkbox-icon {
  margin: 4px 12px 0 0;
  width: 14px;
  height: auto;
}
