@import "../../global/index.scss";

.bottom-nav {
  padding: 22px 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row-reverse;
  column-gap: 15px;
  align-items: center;

  @include tablet {
    padding: 12px 32px;
    box-shadow: 0px -2px 7px rgba(0, 0, 0, 0.03);
    justify-content: space-between;
  }
}

.results-bottom-nav {
  @extend .bottom-nav;
  flex-direction: column-reverse;
  row-gap: 8px;

  @include tablet {
    flex-direction: row-reverse;
  }

  .text {
    font-size: 14px;
    text-align: center;
  }
}

.pdf {
  cursor: pointer;
  flex-basis: 0;
  flex-grow: 1;

  .icon {
    height: 32px;
  }
}

.pdf-wrapper {
  display: none;

  @include desktop {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
}
