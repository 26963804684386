$primary-blue: #0a7fff;
$primary-blue-light: #0082fd;
$primary-blue-dark: #00487a;
$primary-black: #2e2e2e;
$primary-grey: #64686c;
$primary-white: #ffffff;

$secondary-green: #00aa46;
$secondary-green-light: #d5f5e3;
$secondary-orange: #f5be00;
$secondary-red: #fd0000;
$secondary-white: #fcfcfc;

$secondary-grey: #bababa;
$secondary-blue: #dfebfc;
$secondary-blue-light: #f4f7fb;

$light-light-grey: #f2f2f2;
$page-back-grey: #f8f8f8;
$input-back-grey: #fcfcfc;

$questions-titles-black:#2E2E2E