@import "../../global/index.scss";

.bottom-header {
  padding: 18px 20px 18px 20px;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.08);
  display: flex;
  column-gap: 18px;
  align-items: center;
  background-color: white;
  z-index: 4;

  @include tablet {
    padding: 18px 32px;
  }

  @include desktop {
    display: none;
  }

  & > *:first-child {
    flex-shrink: 0;
  }

  .complete-circle {
    height: 60px;
    width: 60px;
    background-color: $secondary-orange;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .progression-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    flex-shrink: 1;

    .top-text {
      font-size: 24px;
      color: $primary-blue-dark;
    }

    .bottom-text {
      color: $primary-grey;
    }
  }

  .pdf {
    cursor: pointer;
    height: 32px;

    @include desktop {
      display: none;
    }
  }
}
