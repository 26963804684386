@import "../../global/index.scss";


.buttons-contaier{
    display: flex;
    flex-direction: row;
    margin: 24px 0 0 0;
    @include desktop {
      width: 335px;
    }
}

.button {
    display: flex;
    padding: 0 16px;
    height: 48px;
    align-items: center;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
    width: 100%;
    justify-content: center;
    h3 {
      padding: 0 0 0 8px;
      max-width: 90%;
    }
  }

  .space{
      width: 16px;
  }
  
  .state-selected {
    background-color: #dfebfc;
    border: 1px solid #0a7fff;
    h3 {
      color: #0a7fff;
    }
  }
  
  .state-unselected {
    background-color: #ffffff;
    border: none;
  }
  
  .message-container{
    background: #F4F7FB;
    border-radius: 5px;
    margin: 24px 0;
    display: flex;
    align-items: flex-start;
    padding: 12px 14px;
    .alert-icon{
      width: 24px;
      height: auto;
      margin: 0 12px 0 0;
    }
    .message-text{
      font-size: 16px;
      line-height: 19px;
      color: #282828;
    }
  }