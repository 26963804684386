@import "./global/index.scss";

#root {
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: $main-font-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: $font-size-body;
}

* {
  box-sizing: border-box;
}

h1 {
  color: $primary-blue;
  font-family: $main-font-light;
  font-size: $font-size-h1;
  font-weight: normal;

  @include desktop {
    font-family: $main-font-regular;
  }
}

h2 {
  color: $primary-blue-dark;
  font-family: $main-font-light;
  font-size: $font-size-body;
  font-weight: normal;
}

h3 {
  color: $questions-titles-black;
  font-family: $main-font-regular;
  font-size: $font-size-h3;
  font-weight: normal;
  margin: 0;
}

h4 {
  color: $questions-titles-black;
  font-family: $main-font-regular;
  font-size: $font-size-h4;
  font-weight: normal;
  margin: 0;
}

h5 {
  color: $questions-titles-black;
  font-family: $main-font-regular;
  font-size: $font-size-label;
  font-weight: normal;
  margin: 0;
}

a {
  color: $primary-blue !important;
  text-decoration: underline;
}

.error-message {
  color: $secondary-red;
  font-size: $font-size-label;
  font-family: $main-font-regular;
  margin: 6px 0 0 0;
}
