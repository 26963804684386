@import "../../global/index.scss";

$max-width-small: 160px;
$max-width-medium: 250px;
$max-width-large: 335px;

.button-primary {
  @include reset-button-style;

  background-color: $primary-blue;
  padding: 15px 32px;
  color: white;
  font-family: $main-font-medium;
  //box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.08);
  width: 100%;

  -moz-transition: all 200ms;
  -webkit-transition: all 200ms;
  transition: all 200ms;

  &:disabled {
    background-color: $light-light-grey !important;
    box-shadow: none !important;
    cursor: default !important;
  }

  @include desktop {
    &:hover {
      background-color: #0064d2;
    }
  }

  &:active {
    background-color: #4ca1ff;
  }

  &.squeezed {
    padding: 7px 32px;
  }

  &.min {
    max-width: max-content;
  }

  &.small {
    max-width: $max-width-small;
  }

  &.medium {
    max-width: $max-width-medium;
  }

  &.large {
    max-width: $max-width-large;
  }

  &.tablet-min {
    @include tablet {
      max-width: max-content;
    }
  }

  &.tablet-small {
    @include tablet {
      max-width: $max-width-small;
    }
  }

  &.tablet-medium {
    @include tablet {
      max-width: $max-width-medium;
    }
  }

  &.tablet-large {
    @include tablet {
      // max-width: $max-width-large;
      max-width: none;
    }
    @include desktop {
      max-width: $max-width-large;
    }
  }

  &.tablet-max {
    @include tablet {
      max-width: none;
    }
  }

  &.desktop-min {
    @include desktop {
      max-width: max-content;
    }
  }

  &.desktop-small {
    @include desktop {
      max-width: $max-width-small;
    }
  }

  &.desktop-medium {
    @include desktop {
      max-width: $max-width-medium;
    }
  }

  &.desktop-large {
    @include desktop {
      max-width: $max-width-large;
    }
  }

  &.desktop-max {
    @include desktop {
      max-width: none;
    }
  }
}

.button-secondary {
  @extend .button-primary;

  box-shadow: none;
  background-color: $secondary-blue;
  color: $primary-blue;
  @include desktop {
    &:hover {
      background-color: #c1daff;
    }
  }

  &:active {
    background-color: #e8f2ff;
  }
}

@for $i from 1 through 5 {
  .grow-#{$i} {
    flex-basis: 0;
    flex-grow: #{$i};
  }
}

.button-back {
  @extend .button-primary;

  box-shadow: none;
  background-color: transparent;
  color: $primary-blue;
  padding: 15px 0;

  @include desktop {
    &:hover {
      background-color: transparent;
    }
  }
  &:active {
    background-color: transparent;
  }
}

@for $i from 1 through 5 {
  .grow-#{$i} {
    flex-basis: 0;
    flex-grow: #{$i};
  }
}
.shadow-none {
  box-shadow: none;
}

.shadow-grey {
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.08);
}

.shadow-blue {
  box-shadow: 0px 5px 9px rgba(0, 128, 255, 0.22);
}
