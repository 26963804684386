@keyframes scaleUp {
  0% {
    transform: scale(1.2);
    top: 10px;
    -webkit-transform: scale(1.2);
  }

  50% {
    top: -5px;
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
  }

  100% {
    top: 0px;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes open {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 400px;
  }
}

@keyframes close {
  0% {
    max-height: 400px;
  }

  100% {
    max-height: 0;
  }
}

@keyframes slideUp {
  0% {
    margin-top: 100vh;
  }

  100% {
    margin-top: 0;
  }
}

@keyframes heightAnimation {
  0% {
    height: 54px;
  }

  50% {
    height: 34px;
  }

  100% {
    height: 44px;
  }
}

.results-animation {
  animation: resultsAnimation 1.7s ease-out forwards;
}

@keyframes resultsAnimation {
  0% {
    opacity: 0;
    margin-bottom: 30px;
  }
  100% {
    opacity: 1;
    margin-bottom: 0px;
  }
}

.display-selected-option {
  overflow: hidden;
  animation: displaySelectedOptionAnimation 1.1s ease-out forwards;
  // transition: 2.2s ease-in-out;
}

@keyframes displaySelectedOptionAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
    height: 0px;
  }
}

.selection-results-animation {
  animation: resultsAnimation 0.7s ease-out forwards;
}

.bounce-button {
  animation: bounceButtonAnimation 0.45s ease-in-out infinite alternate;
}

@keyframes bounceButtonAnimation {
  0% {
    top: -4px;
  }

  100% {
    top: 0px;
  }
}

.reduce-button {
  animation: reduceWidthButtonAnimation 0.5s ease-in-out forwards;
}

@keyframes reduceWidthButtonAnimation {
  0% {
    flex-grow: 0.2;
  }
  100% {
    flex-grow: 1;
  }
}
