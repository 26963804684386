@import "../../global/index.scss";

$black: #282828;
$grey: #bababa;

.input-container {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-end;
  margin: 24px 0 0 0;
}

.my-input {
  font-family: $main-font-regular;
  width: 100%;
  // max-width: 336px;
  height: 36px;
  font-size: $font-size-body;
  padding: 0 8px 0 16px;
  color: $black;
  outline: none;

  &::placeholder {
    color: $grey;
    font-size: $font-size-body;
    padding: 0;
    font-family: $main-font-regular;
  }

  &:focus {
    background-color: $secondary-blue !important;
    border: 1px solid $primary-blue-light !important;
    &::placeholder {
      color: transparent !important;
    }
  }

  @include desktop {
    &:focus {
      &:hover {
        background-color: $secondary-blue !important;
        border: 1px solid $primary-blue-light !important;
      }
    }
    &:hover {
      border: 1px solid $questions-titles-black !important;
    }
  }
}

.state-default {
  background: $primary-white;
  border: 1px solid #bababa;
}

.state-valid {
  background-color: $primary-white;
  border: 1px solid $primary-blue-light;
}

.state-invalid {
  background-color: $secondary-white;
  border: 1px solid $secondary-red;
}

@include desktop {
  .my-input {
    max-width: 336px;
  }
}
